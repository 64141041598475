import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Image from "../../elements/Image";

const Logo = ({ className, white, ...props }) => {
  const classes = classNames("brand", className);

  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        <Image
          src={
            white
              ? require("./../../../assets/images/logo.png")
              : require("./../../../assets/images/logo3.png")
          }
          alt="Open"
          width={200}
          height={80}
        />
      </h1>
    </div>
  );
};

export default Logo;
