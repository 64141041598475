import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Slider from "react-slick";
import Image from "../elements/Image";
import Modal from "../elements/Modal";
// import { useLongPress } from "../../hooks/useLongPress";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Photos = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section section-photos center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "Fotos",
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    arrows: true,
    centerPadding: 30,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const photos = [
        {
      src: "doctor17.jpg",
      subtitle: "",
    },
    {
      src: "doctor16.jpg",
      subtitle: "",
    },
    {
      src: "doctor14.jpg",
      subtitle: "",
    },
    {
      src: "doctor11.jpg",
      subtitle: "",
    },
    {
      src: "doctor6.jpg",
      subtitle: "",
    },
    {
      src: "doctor7.jpg",
      subtitle: "",
    },
    {
      src: "doctor8.jpg",
      subtitle: "",
    },
    {
      src: "doctor12.jpg",
      subtitle: "",
    },
    {
      src: "doctor13.jpg",
      subtitle: "",
    },
    {
      src: "doctor9.jpg",
      subtitle: "",
    },
    {
      src: "doctor10.jpg",
      subtitle: "",
    },
    {
      src: "doctor5.jpeg",
      subtitle: "",
    },
  ];

  const [modalActive, setModalActive] = useState(false);
  const [currentImage, setCurrentImage] = useState();

  const openModal = (e, src) => {
    e.preventDefault();
    setModalActive(true);
    setCurrentImage(src);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setModalActive(false);
    setCurrentImage("");
  };

  return (
    <>
      <section {...props} className={outerClasses} id="photos">
        <div className="container-sm">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content title-photos"
            />
            <Slider {...settings}>
              {photos.map((item, index) => {
                return (
                  <img
                    key={index}
                    src={require(`../../assets/images/${item.src}`)}
                    className="image-item"
                  />
                  //               <Image
                  //                 key={index}
                  //                 src={require(`../../assets/images/${item.src}`)}
                  //                 className="image-item"
                  //                 // onClick={(e) => openModal(e, item.src)}
                  //               >
                  //                 {/* <div style={{ background: '#1b4aa9', fontSize: 18,     top: '50%',
                  // position: 'absolute',
                  // left: '50%' }}>
                  //                   <p>Ampliar</p>
                  //                 </div> */}
                  //               </Image>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
      <Modal show={modalActive} handleClose={closeModal} image={currentImage} />
    </>
  );
};

Photos.propTypes = propTypes;
Photos.defaultProps = defaultProps;

export default Photos;
