import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
// import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    'card-flex',
    'section-services',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Serviços oferecidos',
    // paragraph: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum — semper quis lectus nulla at volutpat diam ut venenatis.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
      id="servicos"
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

          <div className="tiles-item reveal-from-right card-services" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item">
                  <p className="text-md mb-0">
                  Consulta Médica Presencial
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Personalizado e abrangente</span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-right card-services" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item">
                  <p className="text-md mb-0">
                  Teleconsulta
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Personalizado e abrangente</span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-right card-services" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item">
                  <p className="text-md mb-0">
                  Atendimento Domiciliar 
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Personalizado e abrangente</span>
                </div>
              </div>
            </div>
            <div className="tiles-item reveal-from-right card-services" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item">
                  <p className="text-md mb-0">
                  Ecocardiograma
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Personalizado e abrangente</span>
                </div>
              </div>
            </div>
            <div className="tiles-item reveal-from-right card-services" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item">
                  <p className="text-md mb-0">
                  Eletrocardiograma
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Personalizado e abrangente</span>
                </div>
              </div>
            </div>
            <div className="tiles-item reveal-from-right card-services" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item">
                  <p className="text-md mb-0">
                  Teste Ergometrico (ergometria)
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Personalizado e abrangente</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;