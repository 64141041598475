import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Sobre minhas experiências",
    paragraph: "Saiba mais sobre as minhas qualificações profissionais.",
  };

  return (
    <section {...props} className={outerClasses} id="curriculo">
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("../../assets/images/ufba.jpeg")}
                      alt="Features tile icon 01"
                      width={245}
                      height={230}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 features-tiles-item-title">
                    2021-2022:
                  </h4>
                  <p className="m-0 text-sm">
                    Residente de Ecocardiograma na Universidade Federal da Bahia
                    (UFBA)
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("../../assets/images/santa-izabel.png")}
                      alt="Features tile icon 02"
                      width={130}
                      height={130}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 features-tiles-item-title">
                    2019-2021:
                  </h4>
                  <p className="m-0 text-sm">
                    Médico Cardiologista, com residência médica no Hospital
                    Santa Izabel (Santa Casa da Bahia) – RQE 20103
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("../../assets/images/irma-dulce.png")}
                      alt="Features tile icon 03"
                      width={130}
                      height={130}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 features-tiles-item-title">
                    2017-2019:
                  </h4>
                  <p className="m-0 text-sm">
                    Especialista em Clínica Médica, com residência médica no
                    Hospital Santo Antônio (Obras sociais irmã Dulce) – RQE
                    17683
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("../../assets/images/exercito.png")}
                      alt="Features tile icon 04"
                      width={130}
                      height={130}
                    />
                  </div>
                </div>

                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 features-tiles-item-title">
                    2016-2018:
                  </h4>
                  <p className="m-0 text-sm">
                    Foi médico do Exército Brasileiro por 2 anos, servindo no
                    Hospital Geral de Salvador (HGeS)
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("../../assets/images/bahiana.png")}
                      alt="Features tile icon 05"
                      width={130}
                      height={130}
                    />
                  </div>
                </div>

                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 features-tiles-item-title">
                    2009-2015:
                  </h4>
                  <p className="m-0 text-sm">
                    Médico graduado pela Escola Bahiana de Medicina e Saúde
                    Pública (EBMSP)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="content-center">
            {/* Botão de curriculo completo */}
            <a
              href="http://lattes.cnpq.br/3028851811163982"
              target="_blank"
              className="button button-blue button-wide-mobile button-sm"
            >
              Ver o currículo completo
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
