import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Slider from "react-slick";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(pushLeft && "push-left");

  const sectionHeader = {
    title: "Depoimentos",
    paragraph: "A opinião de quem já foi atendido",
  };

  const [loading, setLoading] = useState(false);
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    setLoading(true);

    fetch("https://www.drpaulolobo.com.br/api/hello")
      .then((response) => response.json())
      .then(function (data) {
        setTestimonials(data);
        setLoading(false);
      });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 8000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section {...props} className={outerClasses} id="testimonials">
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            {!loading && testimonials.length > 0 ? (
              <Slider {...settings}>
                {testimonials.map((item) => {
                  return (
                    <div className="tiles-item" key={item.titulo.toString}>
                      <div className="tiles-item-inner">
                        <div className="testimonial-item-content">
                          <p className="text-sm mb-0">{item.comment}</p>
                        </div>
                        <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                          <span className="testimonial-item-name text-color-high">
                            {item.titulo}
                          </span>
                          <span className="text-color-low"> / </span>
                          <span className="testimonial-item-link">
                            {item.dataPublicacao}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <div className="tiles-wrap">
                <h1>Carregando</h1>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
