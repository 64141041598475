import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Input from "../elements/Input";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const outerClasses = classNames(
    "cta section center-content-mobile reveal-from-bottom",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "cta-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );

  const [email, setEmail] = useState();
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const saveEmail = async () => {
    setLoading(true);

    await fetch(`https://www.drpaulolobo.com.br/api/google-sheets`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    })
      .then((response) => {
        setAlert(true);
        setError(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setAlert(true);
        setError(true);
        setLoading(false);
      });
  };

  const onEnter = (event, callback) => event.key === 'Enter' && callback();

  const onChangeInput = (event) => setEmail(event.target.value);

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="cta-slogan">
            <h3 className="m-0">Quer receber novidades?</h3>
          </div>
          <div className="cta-action">
            <div style={{ flexDirection: "row", display: "flex" }}>
              <Input
                id="newsletter"
                type="email"
                label="Subscribe"
                labelHidden
                hasIcon="right"
                value={email || ''}
                onChange={onChangeInput}
                placeholder="Digite seu e-mail"
                onKeyPress={e => onEnter(e, saveEmail)}
              ></Input>
              <div
                style={{
                  display: "flex",
                  background: "#eceded",
                  height: "auto",
                  alignItems: "center",
                  borderRadius: "0 2px 2px 0",
                  padding: "0 7px",
                  cursor: "pointer",
                }}
              >
                {!loading ? (
                  <svg
                    onClick={saveEmail}
                    width="25"
                    height="12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z"
                      fill="#376DF9"
                    />
                  </svg>
                ) : (
                  <svg
                    width="35"
                    height="45"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 100 100"
                  >
                    <path
                      fill="#376DF9"
                      d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                    >
                      <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        dur="1s"
                        from="0 50 50"
                        to="360 50 50"
                        repeatCount="indefinite"
                      />
                    </path>
                  </svg>
                )}
              </div>
            </div>
            {alert === true && error === false && <p style={{ fontSize: 15 }}>Email cadastrado com sucesso</p>}
            {alert === true && error === true && <p style={{ fontSize: 15 }}>Houve um problema ao cadastrar este e-mail</p>}

          </div>
        </div>
      </div>
    </section>
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
